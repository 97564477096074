<template>
  <standard-page :definition="$DEFINITIONS.provenance.completeProcess" title="Completed Processes">
    <template v-slot:breadcrumb>
      <span>
        <router-link :to="{ name: 'provenances-home'}">Provenance</router-link>
      </span>
      <span>Completed Processes</span>
    </template>
    <template v-slot:content>
      <data-table :api="api" :columns="columns" :row-click="view" @ready="onReady" :server-side="true" list-key="processes" table-id="process_table" total-key="total">
      </data-table>
    </template>
  </standard-page>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "CompleteProcesses",
  computed: {
    ...mapGetters(["currentUser"])
  },
  data() {
    return {
      api: `${this.$provenance.defaults.baseURL}/provenance/registries/processes_completed`,
      columns: [
        { type: 'name' },
        { type: 'provenanceProcessStatus' },
        { type: 'created' },
        { type: 'extrinsic' },
          {
              type: 'action',
              defaultContent: `<span class="text-dark qr mg-r-10" style="cursor: pointer" title="View QR">
                                    <i class="fa fa-qrcode fa-lg"></i>
                                </span>
                                `
          }
      ],
      table: null,
      did: null,
    };
  },
  methods: {
      onReady(table) {
          this.table = table;
          let self = this;

          $(async function () {
              $('#process_table tbody').on('click', '.qr', function () {
                  let process = table.row($(this).parents('tr')).data();
                  console.log(process)
                  self.$router.push({name: 'process-qr-print',  params: { registryid: process.registry, processdefinitionid: process.process_definition, processid: process.process }})
              });
          });
      },
    view(process) {
        this.$router.push({ name: 'ongoing-process-steps', params: { registryid: process.registry, processdefinitionid: process.process_definition, processid: process.process } });
      // this.$router.push({ name: 'complete-process-steps', params: { registryid: process.registry, processdefinitionid: process.process_definition, processid: process.process } });
    }
  }
}
</script>

<style scoped>
</style>